import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import { Row, Col, Button } from 'react-bootstrap'
import PageTitle from '../components/PageTitle/PageTitle'
import { Container } from 'react-bootstrap'
import RaffleTicket from '../images/Harley-Raffle-Bannerstand.jpg'

const Events = ({ data }) => {

//const photos = data.allContentfulGallery.edges[0].node.images;
  return (

<Layout>
<SEO title="USS IDAHO Newsletters"/>
<Container fluid className="container-fluid-full">
  <PageTitle pageTitle="Newsletters" >
    {/* {page.pageTitle} */}
  </PageTitle>
  <ContentBlock>
    <Row>
      <Col md={8}>
      <h2 className="text-center">Committee Newsletters</h2>
        <Row>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/3R0OixvtKwiyiqnFI523u5/3e11de8bbf29771ddc14a8c54d3ee98e/USSidahoCommissioningNewsletter-Issue13-March2023.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 13 - March, 2023</p>
                  <Img
                    fluid={data.newsletter13.childImageSharp.fluid}
                    alt="March, 2023 Newsletter"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/4ktjnsftvfYgA5z0Zlz2eT/7c9737392fe0b9026c776a3bb7571485/USSIDAHONewsletter-12-JAN2023.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 12 - January, 2023</p>
                  <Img
                    fluid={data.newsletter12.childImageSharp.fluid}
                    alt="December, 2022 Newsletter"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/50SIX29DijLaauzUudjHr2/5a7949d2c1c67512943dd4904784b434/USSidahoCommissioningNewsletter-Issue11-2022-12-01-Rev06.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 11 - December, 2022</p>
                  <Img
                    fluid={data.newsletter11.childImageSharp.fluid}
                    alt="December, 2022 Newsletter"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/2Ld5mxzysRpHl2dr2i1nCJ/7438c04ff924de7c295ee2c307e6a3e9/USSIDAHONewsletter-10-OCT2022.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 10 - October, 2022</p>
                  <Img
                    fluid={data.newsletter10.childImageSharp.fluid}
                    alt="October, 2022 Newsletter"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/vlI24RJSjK8HCw3cx0z9k/3cbebe6b29dfd0c803689bb499f0bcbd/USSidahoCommissioningNewsletter-Issue09-2022-08-11.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 09 - August, 2022</p>
                  <Img
                    fluid={data.newsletter09.childImageSharp.fluid}
                    alt="August, 2022 Newsletter"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/33YpZ4tk9F2WsJEaUNQEEe/b7f12b119b842045faf87290a368b9d3/USSIDAHONewsletter-8-2022JUN.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 08 - June, 2022</p>
                  <Img
                    fluid={data.newsletter08.childImageSharp.fluid}
                    alt="June, 2022 Newsletter"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/2EfSL2frlakNPrynz0OxVC/c68e74d8cec60ce783e2814648ad5d6f/USSIDAHONewsletter-7-2022MAY.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 07 - May, 2022</p>
                  <Img
                    fluid={data.newsletter07.childImageSharp.fluid}
                    alt="Comic Strip"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/WwTeR5NlMj9EaAyoyceDM/f96ed3d15fff9eb2f7ef153aae1791f6/USSidahoCommissioningNewsletterIssue06-2022-02-24.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 06 - February, 2022</p>
                  <Img
                    fluid={data.newsletter06.childImageSharp.fluid}
                    alt="Comic Strip"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://images.ctfassets.net/3y19xtrybxm5/7HX7nXF50mqsdfx9YHQ0gF/bc062f37790498d52701129767e0894a/comic-strip.jpg" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Sherman's Lagoon</p>
                  <Img
                    fluid={data.comicStrip.childImageSharp.fluid}
                    alt="Comic Strip"
                  />
                  <p className="text-center"><small>Click to download JPG</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/74SdsImwNemY4castCjBX7/b9bc3e2da4a15e92bb137b6106518667/Issue_05_January_2022.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 05 - January, 2022</p>
                  <Img
                    fluid={data.newsletter05.childImageSharp.fluid}
                    alt="USS Idaho Ship's Crest"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/5VM7Vjo4jUJGZZVUBu7Njl/520567b725322f67af42f2881a6759da/Issue_04_October_2021.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 04 - October, 2021</p>
                  <Img
                    fluid={data.newsletter04.childImageSharp.fluid}
                    alt="USS Idaho Ship's Crest"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
          <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/1eCsCQY0CyN8X2Ssy2a4Qc/f23fe79e42eda46f638607fe7090a000/Issue-03-October-2021.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 03 - October, 2021</p>
                  <Img
                    fluid={data.newsletter03.childImageSharp.fluid}
                    alt="USS Idaho Ship's Crest"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/5EimWmV139qPqehgt102yR/6877bc3deb1a6401cd8a4c2f751abe04/Issue_02_July_2021.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 02 - July, 2021</p>
                  <Img
                    fluid={data.newsletter02.childImageSharp.fluid}
                    alt="USS Idaho Ship's Crest"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
          <Col md={3}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/2bl6YjlSLe5bUd46I9FVDl/3963d5d9d50d4f9c31c9baecb9bd122f/Issue_01_June_2021.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">Newsletter 01 - June, 2021</p>
                  <Img
                    fluid={data.newsletter01.childImageSharp.fluid}
                    alt="USS Idaho Ship's Crest"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        </Row>
      </Col>
      <Col md={4}>
        <h2 className="text-center">Ship's Newsletters</h2>
        <Row>
        <Col md={6}>
          <a href="https://assets.ctfassets.net/3y19xtrybxm5/2O9FCvIjakG9GXmZj6WgQK/48e5841477939a01b6c38e13633b8a20/2021-to-2022-IDAHO-CO-newsletter.pdf" target="_blank" rel="noopener noreferrer">
                <div className="text-center">
                  <p className="mb-0">2021 to 2022 CO Newsletter</p>
                  <Img
                    fluid={data.coNewsletter01.childImageSharp.fluid}
                    alt="USS Idaho Ship's Crest"
                  />
                  <p className="text-center"><small>Click to download PDF</small></p>
                </div>
                </a>
          </Col>
        </Row>
      </Col>
    </Row>
   
   </ContentBlock>
    </Container>
</Layout>
  )
}
export const query = graphql`
{
  newsletter13: file(relativePath: {eq: "newsletters/March-2023-Newsletter.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter11: file(relativePath: {eq: "newsletters/Issue-11-December-2022.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter01: file(relativePath: {eq: "newsletters/Issue-01-June-2021.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter02: file(relativePath: {eq: "newsletters/Issue-02-July-2021.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter04: file(relativePath: {eq: "newsletters/Issue-04-October-2021.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter03: file(relativePath: {eq: "newsletters/Issue-03-October-2021.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter05: file(relativePath: {eq: "newsletters/Issue-05-January-2022.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  comicStrip: file(relativePath: {eq: "newsletters/comic-strip-thumb.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  coNewsletter01: file(relativePath: {eq: "newsletters/2021-to-2022-IDAHO-CO-newsletter.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter06: file(relativePath: {eq: "newsletters/Issue-06-February-2022.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter07: file(relativePath: {eq: "newsletters/May-2022-Newsletter.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter08: file(relativePath: {eq: "newsletters/Issue-08-June-2022.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter09: file(relativePath: {eq: "newsletters/Issue-09-August-2022.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter10: file(relativePath: {eq: "newsletters/USSIDAHONewsletter-10-OCT2022.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  newsletter12: file(relativePath: {eq: "newsletters/USS-IDAHO-Newletter-12-1-2023.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Events
